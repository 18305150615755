.ant-select-selection {
  border: 0;
  background: transparent;
}
.ant-select-selection .ant-select-selection__rendered {
  margin: 0 .1rem
}

.ant-select-selection .ant-select-selection__placeholder,
.ant-select-selection .ant-select-search__field__placeholder {
  color: #a0a0a0;
  font-size: .12rem;
}

.ant-select-selection-selected-value {
  font-size: .12rem;
}

.am-progress-bar {
  height: .04rem !important
}
.ant-select-open .ant-select-selection,
.ant-select-focused .ant-select-selection {
  box-shadow: none
}
.ant-modal {
  width: 1000px !important
}
