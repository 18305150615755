.mask-container {
  position: fixed; }

.mask-container .mask {
  position: absolute; }

.mask-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  overflow: hidden; }
  .mask-container .mask {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0; }
    .mask-container .mask.fade-in {
      opacity: 0.3; }
  .mask-container .content {
    /* height: 100%;
    width: 100%; */
    /* position: absolute;
    z-index: 2; */
  }
