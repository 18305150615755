
.logo {
  float: left;
}
.layout {
  height: 100%;
  overflow: scroll;
}

.ant-layout-content {
  flex: none;
  height: auto
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-submenu-horizontal > .ant-menu-submenu:hover,
.ant-menu-submenu-title:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #E94F19 !important;
  border-color: #E94F19 !important;
}
