.slide-page-container, .slide-page-container .close-container {
  position: absolute; }

.slide-page-container .close-container .slide-page-close {
  position: relative; }

.slide-page-container .close-container .slide-page-close {
  display: block; }

.slide-page-container {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  overflow: hidden; }
  .slide-page-container .close-container {
    right: 0;
    top: 0; }
    .slide-page-container .close-container .slide-page-close {
      right: 14px;
      top: 12px;
      z-index: 9;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      font-size: 50px !important;
      font-weight: normal;
      color: #FF6666;
      padding-top: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      padding-left: 15px;
      padding-top: 0; }
  .slide-page-container.info {
    background-color: #fff; }
  .slide-page-container.root {
    background-color: #f5f5f6; }
  .slide-page-container.right {
    transform: translateX(0);
    -webkit-transform: translateX(0); }
    .slide-page-container.right.hide {
      transform: translateX(300%);
      -webkit-transform: translateX(300%); }
  .slide-page-container.down {
    transform: translateY(0);
    -webkit-transform: translateY(0); }
    .slide-page-container.down.hide {
      transform: translateY(300%);
      -webkit-transform: translateY(300%); }
  .slide-page-container.left {
    transform: translateX(0);
    -webkit-transform: translateX(0); }
    .slide-page-container.left.hide {
      transform: translateX(-300%);
      -webkit-transform: translateX(-300%); }
  .slide-page-container.up {
    transform: translateY(0);
    -webkit-transform: translateY(0); }
    .slide-page-container.up.hide {
      transform: translateY(-300%);
      -webkit-transform: translateY(-300%); }
